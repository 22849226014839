<template>
  <f7-popup class="popup-setprice" :class="inputGroup?.value" :tablet-fullscreen="false" :backdrop="true">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ inputGroup?.title }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <section class="form-container">
        <div class="container">
          <f7-list id="formCurrency" class="formContainer" form no-hairlines no-hairlines-between>
            <f7-list-item divider> {{ $t.getTranslation('PNL_CURRENCY_INFORMATION') }} </f7-list-item>

            <f7-list-input v-model:value="formData['CurrencyId']" type="select" name="CurrencyId"
              :label="$t.getTranslation('PNL_CURRENCY')" :required="true"
              :minlength="validationRules?.['CurrencyId']?.minimumLength"
              :maxlength="validationRules?.['CurrencyId']?.maximumLength"
              :placeholder="$t.getTranslation('PNL_TYPE_HERE')" :info="$t.getTranslation('PNL_PLEASE_SELECT_CURRENCY')"
              floating-label outline validate>
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>

              <option v-for="currency in currencyList" :key="'cy_' + currency.CurrencyCode"
                :value="currency.CurrencyId">
                {{ currency.CurrencyCode }} - {{ currency.CurrencyName }}
              </option>
            </f7-list-input>

            <f7-list-item divider> {{ $t.getTranslation('PNL_PRICE_INFORMATION') }}
            </f7-list-item>
            <div style="margin-bottom: 15px;"> </div>


            <div style="margin-bottom: 15px;"> </div>
            <f7-list-item divider> {{ $t.getTranslation('Price Regular') }}
            </f7-list-item>

            <f7-list-input v-model:value="formData['PriceRegular']" type="text" name="PriceRegular"
              :label="$t.getTranslation('PNL_PRICE_REGULAR')" :info="$t.getTranslation('PNL_PRICE_REGULAR_INFO')"
              :required="true" :minlength="validationRules?.['PriceRegular']?.minimumLength"
              :maxlength="validationRules?.['PriceRegular']?.maximumLength" placeholder="0" acceptinput="number"
              floating-label outline validate @blur="calcPrice">
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>

            <f7-list-item divider> {{ $t.getTranslation('Discount %') }} </f7-list-item>
            <f7-list-input v-model:value="formData['PriceDiscount']" type="text" name="discount"
              :label="$t.getTranslation('Discount')" :info="$t.getTranslation('Discount')" :required="true"
              :minlength="validationRules?.['discount']?.minimumLength"
              :maxlength="validationRules?.['discount']?.maximumLength" placeholder="0" acceptinput="number"
              floating-label outline validate @blur="calcPrice">
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>

            <f7-list-item divider> {{ $t.getTranslation('Final Price') }} </f7-list-item>
            <f7-list-input v-model:value="formData['PriceFinal']" type="text" name="finalPrice"
              :label="$t.getTranslation('Final Price')" :info="$t.getTranslation('Final Price')" :required="true"
              :minlength="validationRules?.['finalPrice']?.minimumLength"
              :maxlength="validationRules?.['finalPrice']?.maximumLength" placeholder="0" :disabled="true"
              acceptinput="number" floating-label outline validate>
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>

            <f7-list-item divider> {{ $t.getTranslation('Price for Multiple purchases') }} </f7-list-item>
            <f7-list-input v-model:value="formData['PriceMultiple']" type="text" name="PriceMultiple"
              :label="$t.getTranslation('Price for Multiple purchases')"
              :info="$t.getTranslation('Price for Multiple purchases')" :required="true"
              :minlength="validationRules?.['PriceMultiple']?.minimumLength"
              :maxlength="validationRules?.['PriceMultiple']?.maximumLength" placeholder="0" acceptinput="number"
              floating-label outline validate>
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>
            </f7-list-input>
          </f7-list>
        </div>
      </section>

      <div class="footer-button-container">
        <f7-button class="button-select" fill large @click="setPrice">{{ inputGroup?.buttonText }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { useStore } from '@/store';
import { get } from '@/utils/axios';
import { helpers } from '@/utils/helpers.js';
import { validations } from '@/utils/validations';
import { Dom7 } from 'framework7';
import { f7 } from 'framework7-vue';
import { defineComponent, inject, onMounted, reactive, ref } from 'vue';

const store = useStore();

export default defineComponent({
  name: 'SetPricePopupComponent',
  props: {
    backdrop: {
      type: Boolean,
      default: true
    }
  },
  emits: ['opened', 'closed', 'set'],
  setup(props, { emit }) {
    const $t = inject('$translation');

    const inputGroup = ref({});
    const currencyList = ref([]);

    const formData = reactive({
      CurrencyId: '',
      PriceFinal: 0,
      PriceDiscount: 0,
      PriceRegular: 0,
      PriceMultiple: 0
    });

    const validationRules = reactive({});

    const getCurrencyList = async () => {
      if (currencyList.value.length) return;
      currencyList.value = await get('/public/currency/list');
    };

    const validate = (showNotifications = false) => {
      return validations.validate({
        validateData: formData,
        validationRules,
        showNotifications
      });
    };

    const calcPrice = () => {
      const discountAmount = (formData.PriceRegular * formData.PriceDiscount) / 100;
      formData.PriceFinal = formData.PriceRegular - discountAmount;
    };

    const setPrice = () => {
      if (!validate(true)) return;

      const selectedCurrency = currencyList.value.find(
        (currency) => String(currency.CurrencyId) === String(formData.CurrencyId)
      );

      if (!selectedCurrency) {
        f7.toast
          .create({
            text: $t.getTranslation("Please select a valid currency"),
            position: "center",
            closeTimeout: 2000,
            cssClass: "toast-warning",
          })
          .open();
        return;
      }


      emit("set", {
        type: inputGroup.value.value,
        data: {
          ...formData,
          CurrencyName: selectedCurrency.CurrencyName,
          CurrencyCode: selectedCurrency.CurrencyCode,
          CurrencySymbol: selectedCurrency.CurrencySymbol,
        },

      });
      f7.popup.close(".popup-setprice");
    };

    onMounted(async () => {
      const validationRuleList = await get('/public/validation/list', { Type: 'currency' });
      Object.keys(formData).forEach((key) => {
        if (validationRuleList[key]) {
          validationRules[key] = { ...validationRuleList[key], required: true };
        }
      });
      await getCurrencyList();
    });

    return {
      formData,
      validationRules,
      inputGroup,
      currencyList,
      getCurrencyList,
      setPrice,
      calcPrice
    };
  },
  methods: {
    openPopup(inputGroup, data) {
      this.inputGroup = inputGroup;
      helpers.clearFormData(this.formData);
      helpers.resetForm('#formCurrency');

      setTimeout(() => {
        if (!this.formData.CurrencyId && this.currencyList[0]?.CurrencyId) {
          this.formData.CurrencyId = this.currencyList[0].CurrencyId;
        }

        if (data) {
          Object.assign(this.formData, data);
        }

        Dom7("li[acceptinput='number'] input").on('keypress', (e) => {
          if (!/^[0-9.]+$/.test(String.fromCharCode(e.charCode || e.which))) {
            e.preventDefault();
          }
        });

        f7.popup.open(`.popup-setprice`);
        this.$emit('opened', true);
      }, 10);
    },
    closePopup() {
      f7.popup.close('.popup-setprice');
      this.$emit('closed', true);
    }
  }
});
</script>


<style scoped>
.popup.popup-setprice {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}

.popup.popup-setprice.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}

.popup.popup-setprice.modal-in {
  transform: translate3d(0, 0, 0);
}

.popup.popup-setprice.modal-out {
  transform: translate3d(100vh, 0, 0);
}

.popup.popup-setprice .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}

.popup.popup-setprice .navbar .title {
  padding-left: 10px;
}

.popup.popup-setprice .selectitem-container {
  width: calc(100% - 80px);
  margin: 10px auto;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 60px;
}

.popup.popup-setprice .selectitem-container .selectitem {
  padding: 12px 0px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.popup.popup-setprice .selectitem-container .selectitem .checkbox {
  margin-top: 5px;
  margin-right: 20px;
}

.popup.popup-setprice .selectitem-container .selectitem .checkbox.disabled {
  opacity: 1 !important;
}

.popup.popup-setprice .selectitem-container .selectitem img {
  width: 40px;
  height: auto;
  border-radius: 8px;
  margin-right: 15px;
}

.popup.popup-setprice .selectitem-container .selectitem h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
}

.popup.popup-setprice .selectitem-container .selectitem p {
  margin: 0px;
  padding: 0px;
}

.footer-button-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 55px);
  background: #fff;
  padding: 18px 30px;
  z-index: 999;
  border-top: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-button-container .checkbox-container {
  font-weight: bold;
  text-transform: uppercase;
}

.footer-button-container .checkbox-container .checkbox {
  margin-right: 10px;
}

.footer-button-container .button {
  width: 100%;
}

.popup.popup-setprice .empty-container {
  margin-top: 60px;
}
</style>
